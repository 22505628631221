export enum ADMIN_ROLES {
    ba_admin = 'ba_admin',
    technicien_admin = 'technicien_admin',
    super_admin = 'super_admin',
}

export const ADMIN = {
    URLS: {
        HOME: '/admin',
        LOGIN: '/admin/login',
    },
    ROLES: [
        {
            value: 'SUPER_ADMIN',
            viewValue: 'Super ADMIN',
        },
        {
            value: 'BA_ADMIN',
            viewValue: 'ADMIN BA',
        },
        {
            value: 'TECHNICIEN_ADMIN',
            viewValue: 'ADMIN TECHNICIEN',
        },
    ],
};
